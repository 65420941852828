:root {
  --primaryColor: #003399;
  --secondaryColor: #62d6fc;
  --secondaryColorHover: #56bcdd;
  //  --buttonTextColor: #01063f;
  --buttonTextColor: white;
}

html {
  --scrollbar-width: 2px;
}

html,
body {
  height: 100svh;
  margin: 0;
  padding: 0;
  font-size: 16px;
  overflow: hidden;

  -webkit-tap-highlight-color: transparent;
}

body {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  background: var(--primaryColor);
  padding: 2rem;
  gap: 2rem;

  * {
    box-sizing: border-box;
  }
}

.button-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  max-width: 500px;

  color: white;
  font-family: Helvetica Neue, sans-serif;
  font-weight: 600;
  font-size: 28px;
  gap: 20px;

  .button-wrap {
    display: flex;
    max-height: 70px;

    &[data-isvalid] {
      .result-icon {

        background: #53d769;

        .result-icon--valid {
          display: block;
        }

        .result-icon--invalid {
          display: none;
        }
      }
    }

    &:not([data-isvalid]) {
      .result-icon {

        background: #d32f2f;

        .result-icon--valid {
          display: none;
        }

        .result-icon--invalid {
          display: block;
        }
      }
    }

    .result-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 90px;
      height: 100%;
      margin-left: 1rem;
      background: black;
      border: 2px solid #fff;
      border-radius: 17px;


      svg {
        display: none;
        position: absolute;
        width: 80%;
        height: 80%;
        fill: white;
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
    border: 2px solid #fff;
    width: 100%;
    max-height: 70px;
    padding: 1rem 2rem;
    border-radius: 17px;
    overflow: hidden;
    cursor: pointer;
    user-select: none;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      transition: transform 100ms ease-out;
    }

    &:hover {
      .content {
        transform: scale(1.1);
      }
    }

    &.scanbot {
      img {
        width: 100%
      }
    }
  }
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0;
  background: black;

  transition: all 100ms;
  pointer-events: none;

  .close {
    z-index: 1;
    background: transparent;
    width: 60px;
    height: 60px;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    fill: white;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.active {
    pointer-events: all;
    opacity: 1;
  }
}

#scanbot, #html5qrcodescanner, #opencv {
  position: absolute;
  width: 100%;
  height: 100%;
  //  width: calc(100% + 4rem);
}

